import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import _ from 'lodash'

// Components
import {
  TitleDefault,
  ContentDefault,
  BannerDefault,
  BreadCrumbBar,
  ButtonLineDefault,
} from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Services from '../components/Services'

function DienstenPage({ data, pageContext }) {
  const { wordpressPage: page } = data
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} />
      <div className="diensten-page">
        <BannerDefault className="banner-detail">
          <div className="row h-100">
            <div className="col-12 image-container p-0">
              <BackgroundImage
                fluid={
                  page.acf.header_image_diensten.localFile.childImageSharp.fluid
                }
              />
            </div>
            <div className="container">
              <div className="col-12 col-lg-6">
                <h1>{page.title}</h1>
              </div>
            </div>
          </div>
        </BannerDefault>
        <BreadCrumbBar>
          <div className="row m-0">
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator="-"
              crumbLabel={page.title}
            />
          </div>
        </BreadCrumbBar>
        <div className="empty-space-100" />
        <section className="diensten-row">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <TitleDefault>
                  <span>{page.acf.text_services_links.title_left}</span>
                  <br />
                  <strong>{page.acf.text_services_links.subtitle_left}</strong>
                </TitleDefault>
                <ContentDefault>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page.acf.text_services_links.text_left,
                    }}
                  />
                </ContentDefault>
              </div>
              <div className="col-md-8">
                <Services />
              </div>
            </div>
          </div>
        </section>
        <div className="empty-space-100" />
      </div>
    </Layout>
  )
}

export default DienstenPage

export const DienstPageQuery = graphql`
  {
    wordpressPage(wordpress_id: { eq: 94 }) {
      title
      acf {
        header_image_diensten {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        text_services_links {
          title_left
          subtitle_left
          text_left
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`
